/**
 * userList数组中对象结构
 * - userId: roomid + userId 唯一标记
 * - userName: userName,
 * - avatarUrl: 头像
 * - isUseHuaTong: false, // 是否开启话筒
 * - isUseHuaTongError: false, // 话筒状态是否error
 * - isUseShiPin: false, // 是否开启视频
 * - isUseYangShengQi: true, // 是否开启扬声器
 * - isShare: false, // 是否共享中
 * - sharePaused: false // 是否共享暂停中
 * - isRecord: false, // 是否正在录制
 * - recordPaused: false, // 录制是否暂停
 *
 * - isSpeaking: false, // 是否说话中
 *
 * - roleCode: 20, // 角色信息
 * - isRaiseHand: false, // 是否正在举手

 * - isDbClick: false, // 是否被锁定了
 * - timeMs // 入会时间戳
 */


/**
 * audioList Array
 * item: {
 *  action: start | stop | mute | unmute
 *  pause: true | false, 是否开启mic
 * }
 * **/
import Vue from 'vue'

import { WEIGHT_CONFIG, MAX_CONF_TIME, ROLE_CODE } from '@/constant/index'

const state = {
    userList: [], // 用户列表（包含顶部共享画面的）
    pageIndex: 0, // 当前页
    pageSize: 4, // 每页多少条

    totolUserNumber: 0, // 会场中的总人数

    audioList: [], // 音频通道列表

    speakList: [], // 发言人列表
    lastSpeaker: null // 最新的发言人
}

const mutations = {
    // 添加用户，把一个用户插入到末尾 TODO: 补充去重逻辑
    addUser(state, user) {
        // for (let index = 0; index < state.userList.length; index++) {
        //     const userItem = state.userList[index]

        //     if (!userItem.isTopShare) {

        //         delete userItem._old

        //         userItem._old = JSON.parse(JSON.stringify(userItem))
        //     }
        // }
        const default_user = {
          userId: '',
          userName: '',
          avatarUrl: '',
          isUseHuaTong: false, // 是否开启话筒
          isUseHuaTongError: false, // 话筒状态是否error
          isUseShiPin: false, // 是否开启视频
          isUseYangShengQi: false, // 是否开启扬声器
          isShare: false, // 是否共享中
          sharePaused: false, // 是否共享暂停中
          isSpeaking: false, // 是否说话中

          roleCode: 0,
          isRaiseHand: false,
          isRecord: false,
          recordPaused: false,
          timeMs: '', // 入会时间
          isShareDoc:false//文档共享
        }

        //避免重复添加
        const filterUsers = state.userList.filter(u => {
          return u.userId === user.userId
        })

        if (filterUsers.length  === 0) {
          state.userList.push(Object.assign(default_user, user))
        }
    },

    // 添加用户, 把一个用户插入到第一个位置
    unshiftUser(state, user){
      //避免重复添加
      const filterUsers = state.userList.filter(u => {
        return u.userId === user.userId
      })

      if (filterUsers.length  === 0) {
        state.userList.unshift(user)
      }
    },

    // 删除用户
    removeUser(state, user, isCheck) {
        let i = -1;

        for (let index = 0; index < state.userList.length; index++) {
            const userItem = state.userList[index]
            delete userItem._old
            userItem._old = JSON.parse(JSON.stringify(userItem))

            if (userItem.userId === user.userId) {
                i = index;
            }
        }

        if (i > -1) {
            state.userList.splice(i, 1);
        }
    },

    // 设置总人数
    updateTotolUserNumber: function(state, val) {
        state.totolUserNumber = val
    },

    // 删除第一个位置的分享画面
    removeShareView(state) {
        let topIndex = -1

        for (let i = 0; i < state.userList.length; i++) {
            const item = state.userList[i]

            if (item.isTopShare) {
                topIndex = i
                break
            }
        }

        if (topIndex > -1) {
            state.userList.splice(topIndex, 1)
        }
    },

    // 改变userList中某一项的值, user: {} 一个用户对象
    updateUser(state, user) {
        for (let index = 0; index < state.userList.length; index++) {
            const userItem = state.userList[index]
            if (userItem.userId === user.userId) {

                delete userItem._old

                userItem._old = JSON.parse(JSON.stringify(userItem))

                for (const key in user) {
                  state.userList[index][key] = user[key]
                }
                break;
            }
        }
    },

    // 设置第几页
    updatePageIndex: function(state, pageIndex) {
        state.pageIndex = pageIndex
    },
    // 设置每页多少条
    updatePageSize: function(state, pageSize) {
        state.pageSize = pageSize
    },


    // 添加speak
    addSpeak(state, user) {
        state.speakList.push(user)
    },

    // 删除speak
    removeSpeak(state, user) {
        let i = -1;

        for (let index = 0; index < state.speakList.length; index++) {
            const userItem = state.speakList[index]

            if (userItem.userId === user.userId) {
                i = index;
                break;
            }
        }

        if (i > -1) {
            state.speakList.splice(i, 1);
        }
    },

    // 更新
    updateLastSpeaker(state, user) {
        state.lastSpeaker = user
    },


    // 添加audio
    addAudio(state, user) {
        // state.audioList.push(user)
        // Hack - 解决问题
        let i  = -1;
        for (let index = 0; index < state.audioList.length; index++) {
          const userItem = state.audioList[index]
          if (userItem.userId === user.userId) {
            i = index;
            break;
          }
        }

        if(i < 0) {
          state.audioList.push(user)
        }

    },

    // 删除audio
    removeAudio(state, user) {
        let i = -1;

        for (let index = 0; index < state.audioList.length; index++) {
            const userItem = state.audioList[index]

            if (userItem.userId === user.userId) {
                i = index;
                break;
            }
        }

        if (i > -1) {
            state.audioList.splice(i, 1);
        }
    },

    // 更新audio
    updateAudio(state, user) {
        for (let index = 0; index < state.audioList.length; index++) {
            const userItem = state.audioList[index]

            if (userItem.userId === user.userId) {
                for (const key in user) {
                    userItem[key] = user[key]
                }
                break;
            }
        }
    },

    // reset
    reset(state) {
        state.userList = []
        state.pageIndex = 0
        state.pageSize = 4

        state.totolUserNumber = 0
        state.audioList = []

        state.speakList = []
        state.lastSpeaker = null

        
    },

    // Disconnected reset
    disconnectedReset(state) {
        state.userList = []
        state.pageIndex = 0

        state.speakList = []
        state.lastSpeaker = null

        state.audioList = []
    },


}

// 计算属性
const getters = {
    getRealUserList(state) { // 真正的用户列表
        return state.userList.filter(i => !i.isTopShare)
    },

    getLockUser(state) { // 获取锁定的用户
        return state.userList.find(i => !i.isTopShare && i.isDbClick)
    },

    getTopShare(state) { // 获取topshare
        return state.userList.find(i => i.isTopShare)
    },

    // 获取指定id的用户信息
    getUser: (state) => (userId) => {
        let target = {}

        for (let index = 0; index < state.userList.length; index++) {
            const userItem = state.userList[index];
            if (userItem.userId === userId) {
                target = userItem
                break
            }
        }

        return target
    },

    getWorkUserList(state,getters,main){
      const findShare =  state.userList.find(item => item.isShare && !item.isTopShare);

      if(main.meet.isFocusScreen != 0){ //如果是有焦点的情况 普通共享需要多一个item
        const userList = [...state.userList]
        if(findShare && findShare.userId.indexOf('cloudshare') == -1 && !findShare.isShareDoc){
          let shareDats = {...findShare,
            isTopShare:true
          }
          userList.push(shareDats)
          return userList
        }else{
          return state.userList
        }
      }else{
        return state.userList.filter(i=>i.userId.indexOf('cloudshare') == -1)
      }
    },
    // 获取当前页的用户信息
    pageList: (state,getters,main) => {
        const { pageIndex, pageSize } = state;
        const userList = getters["getWorkUserList"]

        const findShare = userList.find(item => item.isShare);
        const findShareDoc = userList.find(item => item.isShareDoc);
        if(userList.length < 3 && !findShare && !findShareDoc && main.meet.isFocusScreen == 0) return []; // 少于 3项，不渲染分页 有共享、有云共享
       
        const sortUserList = userList.map(item => {
            let weight = 0
            let videoWeight = 0

            const nowTime = new Date().getTime()

            let duration = nowTime - item.timeMs

            duration = duration > 0 ? duration : 0

            if (item.userId === Vue.prototype.$configs.peerId) { // 是自己
              weight = WEIGHT_CONFIG.SELF + 10 * MAX_CONF_TIME
            } else if (item.userId.indexOf('cloudshare') != -1) { // 是云共享
              weight = WEIGHT_CONFIG.SHARE + 10 * MAX_CONF_TIME + 10
              item.isTopShare = true
            } else if (item.isTopShare) { // 是焦点共享
              weight = WEIGHT_CONFIG.SHARE + 10 * MAX_CONF_TIME + 10
            } else if (item.roleCode === ROLE_CODE.HOST) { // 是主持人
              weight += WEIGHT_CONFIG.HOST
            } else if (item.roleCode === ROLE_CODE.CO_HOST) { // 是联席主持人
              weight += WEIGHT_CONFIG.CO_HOST
            } else {
              weight += WEIGHT_CONFIG.USER
            }

            weight = (weight * MAX_CONF_TIME) + duration

            if (
              item.isUseShiPin
            ) { // 正在开启视频
                videoWeight = 10 * MAX_CONF_TIME
            }
            return {
              ...item,
              userWeight: weight
            }
        }).sort((a, b) => {
          const v1 = a.userWeight
          const v2 = b.userWeight

          return v2 - v1
        })
        // 按照每页个数和当前页数切分
        const arr = [];

        /**
         * 分页：只缓存当前页的 前后两页
         * 获取渲染的 startIndex、endIndex
         * for 循环期间的
         * **/

        let current = pageIndex,
            prev = pageIndex - 1,
            next = pageIndex + 1;

        console.log('current page', current);

        /**
         * 每页的 范围：
         * current: (current-1)*pageSize ~ current*pageSize
         * prev: (prev-1)*pageSize ~ prev*pageSize
         * next: (next-1)*pageSize ~ next*pageSize
         *
         * **/

        // let _prev_arr = [], _current_arr = [], _next_arr = [];

        // 使用 array slice

        // prev
        let _o_prev = {
          page: prev,
          count:0,
          list: []
        }
        let _pbegin = (prev-1)*pageSize, _pend = prev*pageSize;
        if(_pbegin >= 0) {
          let _prev_arr = sortUserList.slice(_pbegin, _pend);

          _o_prev = {
            page: prev,
            count: _prev_arr.length,
            list: _prev_arr
          }
        }

        if(_o_prev.count > 0) arr.push(_o_prev)


        // current
        let _o_current = {
          page: current,
          count:0,
          list: []
        }
        let _cbegin = (current-1)*pageSize, _cend = current*pageSize;
        if(_cbegin >= 0) {
          let _current_arr = sortUserList.slice(_cbegin, _cend);

          _o_current = {
            page: current,
            count: _current_arr.length,
            list: _current_arr
          }
        }

        if(_o_current.count > 0) arr.push(_o_current);


        // next
        let _o_next = {
          page: next,
          count:0,
          list: []
        }
        let _nbegin = (next-1)*pageSize, _nend = next*pageSize;
        if(_nbegin >= 0) {
          let _next_arr = sortUserList.slice(_nbegin, _nend);

          _o_next = {
            page: next,
            count: _next_arr.length,
            list: _next_arr
          }
        }

        if(_o_next.count > 0) arr.push(_o_next);
        return arr

    },

    // 获取发言的用户
    getSpeakUser: (state) => {
        const len = state.speakList.length

        if (len > 0) {
            return state.speakList[len - 1]
        } else {
            return null
        }
    },


    // 当前userList结构的最大页数，最小为1
    maxPageIndex: (state) => {
        const { userList, pageSize } = state

        return Math.ceil(userList.length / pageSize) || 1
    },

    // // 当前会场中的人数
    // userNumber: (state) => {
    //     return state.userList.length
    // },

    // 是否有上一页
    isPrev: (state) => {
        return state.pageIndex !== 1
    },
    // 是否有下一页
    isNext: (state) => {
        const { userList, pageSize, pageIndex } = state

        const maxPageIndex = Math.ceil(userList.length / pageSize) || 1

        return pageIndex < maxPageIndex
    },

    // 大图显示逻辑
    userMain: (state,getters,main) => {
      console.error('执行one computed------------', state.userList);

      if(state.userList.length <= 1) return null;

      const findShare = state.userList.find(item => item.isShare);

      const selfInfo = state.userList.filter(item => item.userId == Vue.prototype.$configs.peerId)[0];

      if(!selfInfo) return null;
      

      //焦点
      if (main.meet.isFocusScreen != 0) {
        console.log('命中了焦点----------',main.meet.isFocusScreen)
        const findFocusScreen = state.userList.find(item => {
          return item.userId == main.meet.isFocusScreen
        })
        // console.error(111111,findFocusScreen)
        return findFocusScreen
      }

      if(state.userList.length == 2 && !findShare) {
        let other = null;
        state.userList.map(item => {
          if(item.userId != selfInfo.userId) other = item;
        })

        if(other) return other;
      }
      

      // reset当前是否为共享流

      //先考虑锁定 > 焦点 > 分享 > 发言 > 主持人 > 最早入会的人

       


        // 分享

        if (
          findShare
        ) {
          console.log('命中了共享----------', findShare)
          return findShare
        }

        if (
          getters.getSpeakUser
        ) {
          console.log('命中了大画面中正在说话的人-----------')
          return getters.getSpeakUser
        } else { // 发言人数组为空，这时候来查看是否有最晚的发言人
          if (
            state.lastSpeaker
          ) {
            console.log('命中了大画面中最晚发言人-----------')
            return state.lastSpeaker
          }
        }

        // 主持人
        const findHost = state.userList.find((item)=>{
          return item.roleCode === ROLE_CODE.HOST
        })
        if (
          findHost
        ) {
          console.log('命中了主持人----------')
          return findHost
        }


        // 最早入会
        let minIndex = -1
        let minMs = ''


        for (let index = 0; index < state.userList.length; index++) {

          const item = state.userList[index];

          const { timeMs } = item;

          if (!minMs) { // 不存在，第一次
            minMs = timeMs
            minIndex = index
          } else {

            if (minMs >= timeMs) {
              minMs = timeMs
              minIndex = index
            }
          }

        }


        if (minIndex > -1) {
          console.log('命中了最早入会----------');
          console.log('user', state.userList[minIndex]);
          return state.userList[minIndex]
        }
        return null
    },

    // 右上角 显示发言方
    abbreviationUser: (state, getters, main) => {
      const userMain = getters.userMain;

      if(!userMain) return null;
      
      if(!userMain.isShare && !main.meet.isFocusScreen) return null;
      
      const userList = state.userList.filter(i=>i.userId.indexOf('cloudshare') == -1);

      // if(userList.length == 2 && main.meet.isFocusScreen !=1){
      //   return userList.find(item => {
      //     return item.userId != main.meet.isFocusScreen
      //   });
      // }

      if(userList.length < 3 && !main.meet.isFocusScreen) return null;

      const member = state;

      // 1. 发言方
      if (getters.getSpeakUser) {

        if(getters.getSpeakUser.userId == Vue.prototype.$configs.peerId) return null; 
        return getters.getSpeakUser;
      }

      const lastSpeaker = member.lastSpeaker;
      if(lastSpeaker) {
        if(lastSpeaker.userId == Vue.prototype.$configs.peerId) return null; // 发言方 是自己 直接显示自己（另一个组件）

        return lastSpeaker;
      }

      let _user = null;

      for (let index = 0; index < userList.length; index++) {
        const item = userList[index];

        if(item.userId == Vue.prototype.$configs.peerId) continue;


        // 最早入会
        if(!_user) {
          _user = item;
        } else if( item.timeMs < _user.timeMs) {
          _user = item;
        }

        // 3. 主持人
        if(item.roleCode === ROLE_CODE.HOST) {
          _user = item;
          break
        }

      }

      
      return _user;

    },

}

const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
