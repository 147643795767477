/**
 * 会议全局状态
 */
const state = {
    lockedState: 0, // 会议全局的锁定状态： 0未锁定；1锁定

    allowEarlyEntry: 0, // 是否允许提前进入  1：是；0否；

    muteJoinMeeting: 0, // 成员加入会议静音 1：是；0否；

    playTips: 0, // 成员加入会议时播放提示因 1：是；0否；

    allowSelfUnmute: 1, // 允许成员自我解除静音 1是；0：否

    ownerPasswordEnable: 0, // 是否有主持人密码，0否，1是

    passwordEnable: 0, // 是否使用密码，0否，1是

    agendaPermission: 0, // 议议程权限 0：所有人可见 1：仅主持人可见

    allMuteState: 0, // 全局的静音状态  0：全体解除静音  1：全体静音

    recordPermission: 0, // 录制权限  0：所有人可录制  1：仅主持人可录制

    sharePermission: 0, // 共享权限  0：所有人可共享  1：仅主持人可共享

    ownerId: '', // 会议所有者id

    ownerName: '', // 会议所有者name

    links: '', // 邀请链接

    userRoleCode: 0, // 当前用户的角色

    meetName: '', // 当前会议名称

    password:'',//会议密码

    layoutType: 1, // 1：9宫格 2：25宫格 3：演讲者右侧 4：演讲者上方 5：演讲者全屏

    isFullModel: false, // 是否为全屏模式 true：全屏 false：非全屏

    shareScale: '', // 缩放级别 1: 适应窗口 2: 50% 3: 100% 4: 150% 5: 200% 6: 300%

    isShowCountDownPanel: false, // 是否显示倒计时设置面板
    isShowCountDownTip: false, // 是否显示倒计时面板

    isNetworkDisconnect: false, // 网络是否断开

    meetDialogInfo: null ,// 全局会议弹窗

    micNoGrant: null ,// 麦克风无权限
    cameraNoGrant: null,  // 摄像头无权限

    isLandscape: false, // 是否为横屏模式 true：横屏 false：竖屏

    cloudRecorState: 0,  //云录制状态 开始:10 暂停:20 恢复:30 结束:40

    raisehandPopoverShow: false, //是否展示举手提示

    showNewChatTips: false, //是否有最新聊天提醒

    showChatPop: false, //是否展示聊天半屏

    docShareUserId : null, //文档共享者共享者ID
    isFocusScreen: 0,  //是否开启焦点画面

    isShareDoc : false, //是否文件共享
    shareFireId : '',//共享文件ID
    shareDocTotalPage : 0,//共享文档总页数
    shareType : 0,//文档共享类型。1：普通文档。2 : 用后即焚
    curPage : 0,//当前页数
    thumbData : [],//缩略图

    projectType:0 //代表台类型

}

const mutations = {
    updateGlobalMeetState(state, data) {
        for (const key in data) {
            state[key] = data[key]
        }
    },


    // reset
    reset(state) {
        state.allowEarlyEntry = 0 // 是否允许提前进入  1：是；0否；
        state.muteJoinMeeting = 0 // 成员加入会议静音 1：是；0否；
        state.playTips = 0 // 成员加入会议时播放提示因 1：是；0否；
        state.allowSelfUnmute = 1 // 允许成员自我解除静音 1是；0：否
        state.ownerPasswordEnable = 0 // 是否有主持人密码，0否，1是
        state.passwordEnable = 0 // 是否使用密码，0否，1是
        state.agendaPermission = 0 // 议议程权限 0：所有人可见 1：仅主持人可见
        state.allMuteState = 0 // 全局的静音状态  0：全体解除静音  1：全体静音
        state.recordPermission = 0 // 录制权限  0：所有人可录制  1：仅主持人可录制
        state.sharePermission = 0 // 共享权限  0：所有人可共享  1：仅主持人可共享
        state.lockedState = 0 // 锁定状态： 0未锁定；1锁定
        state.isFullModel = false // 是否为全屏模式 true：全屏 false：非全屏
        state.shareScale = '' // 共享缩放级别
        state.layoutType = 1 // 1：9宫格 2：25宫格 3：演讲者右侧 4：演讲者上方 5：演讲者全屏
        state.ownerId = '' // 会议所有者id
        state.ownerName = '' // 会议所有者name
        state.links = '' // 要求链接
        state.userRoleCode = 0 // 当前用户的角色
        state.meetName = '' // 会议名
        state.password =''//会议密码
        state.isNetworkDisconnect = false // 网络是否断开

        state.isShowCountDownPanel = false 
        state.isShowCountDownTip = false

        state.meetDialogInfo = null,
        state.micNoGrant= null ,// 麦克风无权限
        state.cameraNoGrant= null,  // 摄像头无权限

        state.isLandscape = false, //默认竖屏

        state.raisehandPopoverShow = false, //默认不展示举手提示

        state.showNewChatTips = false, //默认不展示最新消息提醒

        state.showChatPop = false //默认不展示聊天半屏
        state.cloudRecorState= 0  // 云录制状态
        
        state.docShareUserId = null//文档共享者共享者ID
        state.isFocusScreen = 0  // 是否开启焦点画面

        state.isShareDoc = false //是否文件共享
        state.shareFireId = ''//共享文件ID
        state.shareDocTotalPage = 0//共享文档总页数
        state.shareType = 0//文档共享类型。1：普通文档。2：用后即焚
        state.curPage = 0//当前页数
        state.thumbData = []//缩略图

        state.projectType = 0 // 代表台类型

    }
}

const getters = {

}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}