import Vue from 'vue';
import Home from './Home.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import VConsole from 'vconsole';
import YBMeetSDK from './YBMeetSDK';
import { loganLog } from "@/utils/log";
window.sdk = new YBMeetSDK({ appId: '', appSecret: '', mode: process.env.VUE_APP_ENV });
Vue.prototype.sdk = window.sdk;
import Vant from 'vant';
import 'vant/lib/index.css';
import 'vant/lib/index.less'; // 引入less样式 主题配置
import VueClipboard from 'vue-clipboard2';
Vue.use(Vant);
Vue.use(VueClipboard);
loganLog('程序开始版本号----v1.1.8', '[main]');
// iconfont
import '@/assets/css/iconFont/iconfont.css';
import { getAcceptLanguage } from '@/utils/auth';
import zh from "@/assets/lang/zh.js";
var langVal = getAcceptLanguage();
if (process.env.VUE_APP_ENV != 'master')
    new VConsole();
Vue.use(VueI18n);
var i18n = new VueI18n({
    locale: 'zh-CN',
    silentFallbackWarn: true,
    fallbackLocale: 'zh-CN',
    messages: {
        // 'en-US': en,
        'zh-CN': zh
    }
});
import configs from '@/lib/core/configs.js';
import tools from '@/lib/core/tools.js';
import timer from '@/lib/core/timer.js';
import './permission'; // permission control
Vue.config.productionTip = false; // 以阻止 vue 在启动时生成生产提示
// 组件部分
// 业务核心部分
Vue.use(configs); // 装配项目配置文件
Vue.use(tools); // 装配项目工具文件
Vue.use(timer); // 装配计时器功能
// 封装 iconfont
import MyIcon from '@/components/MyIcon.vue';
import ErrorHandler from '@/components/ErrorHandler.vue';
Vue.component('my-icon', MyIcon);
Vue.component('error-handler', ErrorHandler);
// create eventBus
import bus from '@/utils/bus.js';
Vue.prototype.$eventBus = bus;
// 注册双击事件
var VueTouch = require('vue-touch');
VueTouch.registerCustomEvent('doubletap', {
    type: 'tap',
    taps: 2
});
Vue.use(VueTouch, { name: 'v-touch' });
// 项目 storage 对象
import MeetingStorage from './utils/meetingStorage';
Vue.prototype.$meetingStorage = new MeetingStorage();
new Vue({
    router: router,
    store: store,
    i18n: i18n,
    render: function (h) { return h(Home); }
}).$mount('#app');
